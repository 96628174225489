import React from 'react'
import IcFacebook from '../images/ic-facebook.svg'
import IcInstagram from '../images/ic-instagram.svg'
import { Link } from 'react-router-dom'
import moment from 'moment'

const Footer2 = () => {
    const scrolltoTop = ()=>{
        window.scrollTo(0, 0);
    }
  return (
    <section className='footer footerv2'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 col-md-4 col-sm-12'>
            <h4>Menu</h4>
          <Link to='/history' onClick={scrolltoTop}>History</Link>
          <Link to='/contact' onClick={scrolltoTop}>Contact</Link>  
                
          </div>
          <div className='col-lg-3 col-md-4 col-sm-12'>
            <h4>WeMoke</h4>
            <Link to='/faq' onClick={scrolltoTop}>FAQs</Link>    
            <Link to='/privacy-policy' onClick={scrolltoTop}>Privacy Policy</Link>    
            <Link to='/terms-condition' onClick={scrolltoTop}>Terms of Use</Link>    
          </div>
          <div className='ml-auto'>
            <h4>Social</h4>
            <ul className='socialmedia'>
              <li><a href='https://www.facebook.com/WeMokeRentals/' target='_blank'><img src={ IcFacebook } /></a></li>
              <li><a href='https://www.instagram.com/wemokerentals/' target='_blank'><img src={ IcInstagram } /></a></li>
            </ul>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12 text-center'>
            <p>&copy; {moment().format('YYYY')} All Rights Reserved by WeMoke LLC`</p>
          </div>
        </div>
      </div>
    </section>
    
  )
}

export default Footer2