import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useLocation } from "react-router-dom";
import axios from 'axios';
import successIcon from "./success.svg"
import Download from '../image/download.svg'
import Loading from "../../utils/loading/Loading"
// import imageCompression from "browser-image-compression";

function ThankYouPage() {
  let search = useLocation()?.search;
  const reservationId = new URLSearchParams(search).get("R");
  const [isVehiclePhotoUploaded, setIsVehiclePhotoUploaded] = useState(false)
  const [isCheckout, setCheckout] = useState(undefined)
  const [isAlreadyCheckout, setIsAlreadyCheckout] = useState(false)
  const [isMessage, setIsMessage] = useState('')

  const [isNext, setIsNext] = useState(true);
  const [isError, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [formData, setFormData] = useState({ mokeImages: [] })
  const [frontImage, setFrontImage] = useState([])
  const [driverSideImage, setDriverSideImage] = useState([])
  const [rearImage, setRearImage] = useState([])
  const [passengerSideImage, setPassengerSideImage] = useState([])
  const [isLoading, setLoading] = useState(false)

  const onUpload = async () => {
    setLoading(true)
    setIsNext(false)
    try {
      let err;
      // setRemoveImageActive(false)
      if (formData?.mokeImages?.length !== 4) {
        err = true;
        setError(true)
        setErrorMsg("4 Images required!")
      }
      if (!err) {
        // const { data } = await axios.put(`http://localhost:5000/api/checkout?reservationId=${reservationId}`, formData)
        const { data } = await axios.put(`https://stg-api.wemoke.com/api/checkout?reservationId=${reservationId}`, formData)
        setIsVehiclePhotoUploaded(true)
        setCheckout(data?.success)
        setLoading(false)
        setIsMessage(data?.message)
        setIsAlreadyCheckout(data?.alreadyCheckout)
      }
      setIsNext(true)
      setLoading(false)
    }
    catch (error) {
      setLoading(false)
      setIsNext(true)
      setError(true)
      setErrorMsg(error?.message)
      setCheckout(false)
      setIsMessage(error?.message)
    }
  }

  const onDrop = async (files, imageType) => {
    setError(false)
    setLoading(true)
    var _URL = window.URL || window.webkitURL
    let MokeImageArray = [];
    let FrontImageArray = []
    let DriverSideImageArray = []
    let RearImageArray = []
    let PassengerSideImageArray = []
    files.forEach(async file => {
      if ((file.type.split("/")[0] === "image"
      ) && file.type.split("/")[1] !== "gif") {
        var img = new Image()
        let reader = new FileReader()
        reader.onloadend = async () => {
          img.src = _URL.createObjectURL(file)
          img.onload = async () => {
            var resized = compressedImage(img)
            let name = file.name ? file.name.replace(/-|\s/g, "-").trim() : Math.floor(Math.random() * 100);
            imageType === "frontImage" && FrontImageArray.push({ name, imagePreviewUrl: resized })
            imageType === "driverSideImage" && DriverSideImageArray.push({ name, imagePreviewUrl: resized })
            imageType === "rearImage" && RearImageArray.push({ name, imagePreviewUrl: resized})
            imageType === "passengerSideImage" && PassengerSideImageArray.push({ name, imagePreviewUrl: resized })

            imageType === "frontImage" && setFrontImage({ images: FrontImageArray })
            imageType === "driverSideImage" && setDriverSideImage({ images: DriverSideImageArray })
            imageType === "rearImage" && setRearImage({ images: RearImageArray })
            imageType === "passengerSideImage" && setPassengerSideImage({ images: PassengerSideImageArray })
            setFormData(
              {
                ...formData,
                mokeImages: [
                  ...formData?.mokeImages,
                  ...MokeImageArray,
                  ...FrontImageArray,
                  ...DriverSideImageArray,
                  ...RearImageArray,
                  ...PassengerSideImageArray
                ]
              }
            )
          }
        }
        reader.readAsDataURL(file)
        setLoading(false)
      } else {
        setError(true)
        setLoading(false)
        setErrorMsg("Invalid File Type")
      }
    })

  }

  const onDropRejected = (files) => {
    if (files) {
      files.forEach(file => {
        setError(true)
        setErrorMsg("Please insert a file less than 3 MB!")
      })
    }
  }

  // useEffect(() => {
  // checkout()
  // eslint-disable-next-line
  // }, [])
  return (
    <>
      {
        isVehiclePhotoUploaded ?
          (
            isCheckout ?
              <div className="sucess-main-wrapper">
                <div className="container">
                  <div className="success-wrapper">
                    <img src={successIcon} alt="" className="success-icon" />
                    <h1 className="success-title">SUCCESS</h1>
                    {isAlreadyCheckout && <p className="messege">{isMessage}</p>}
                    <h2>Thank you for renting a Moke!</h2>
                    <p>We hope it made your day a little brighter.</p>

                    <p>If you're interested in purchasing a Moke, please contact

                    </p>
                    <div className="mail-succes-msg"><a href="mailto:admin@wemoke.com" rel="noreferrer" target="_blank">admin@wemoke.com</a></div>
                  </div>
                </div>
              </div>
              :
              (
                isCheckout === undefined ?
                  <div>
                    <h1>Please wait...</h1>
                  </div>
                  :
                  <div className="sucess-main-wrapper">
                    <div className="container">
                      <div className="success-wrapper">
                        <h1 className="failed-msg">Failed</h1>
                        {/* <p className="error-alert">Error: {isMessage}</p> */}
                        <p>Checkout not success please try again or hit checkout button below</p>
                        <button onClick={() => setIsVehiclePhotoUploaded(false)} className="checkout-btn">Checkout</button>
                      </div>
                    </div>
                  </div>
              )
          )
          :
          <>
            <div>
              <div className='container'>
                <div className='chatbox-renter'>
                  <div className='chatbot-renter-glass'>
                    <h6 className='pTagMarginLeft  text-center chatbot-heading'>Upload moke images (4 Images)</h6>
                    <div className='upload-img-wrapper'>
                      <h6 className='pTagMarginLeft  text-center upload-img-text'>Front image</h6>

                      {(!frontImage || frontImage?.length <= 0) ?
                        <>
                          <div className='dropzone'
                          >
                            {
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  onDrop(acceptedFiles, "frontImage")
                                }}
                                maxSize={3145728 * 10}
                                minSize={1000}
                                multiple={false}
                                onDropRejected={(file) => onDropRejected(file)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section className='text-center upload-image' style={{
                                    border: '1px dashed #000',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '15px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '6px',
                                  }}
                                  >
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <img src={Download} alt="Loading..." />
                                      <p >Drag 'n' drop moke images here, or click to select files</p>
                                      <p>PDF's not accepted (on image upload)</p>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            }
                          </div>
                        </>
                        :
                        <div className="imageGrid upload-image-center">
                          {frontImage && frontImage?.images && frontImage?.images.map((image, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className='imagePriviewBox'>
                                  <img src={image?.imagePreviewUrl} alt="vehicles" className='img-control' />
                                  <div className='closeSpanDiv image-cross' >
                                    <span
                                      onClick={
                                        () => {
                                          // if (isRemoveImageActive) {
                                          frontImage?.images?.splice(index, 1)
                                          formData?.mokeImages?.splice(index, 1)
                                          setFrontImage(frontImage?.images)
                                          setFormData({
                                            ...formData,
                                            mokeImages: formData?.mokeImages
                                          })
                                          // }
                                        }
                                      }
                                    >&times;</span>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      }
                    </div>
                    <div className='upload-img-wrapper'>
                      <h6 className='pTagMarginLeft text-center upload-img-text text-font'>Driver side image</h6>
                      {(!driverSideImage || driverSideImage?.length <= 0) ?
                        <>
                          <div className='dropzone'
                          >
                            {
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  onDrop(acceptedFiles, "driverSideImage")
                                }}
                                maxSize={3145728 * 10}
                                minSize={1000}
                                multiple={false}
                                onDropRejected={(file) => onDropRejected(file)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section className='text-center upload-image' style={{
                                    border: '1px dashed #000',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '15px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '6px',
                                  }}
                                  >
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <img src={Download} alt="Loading..." />
                                      <p >Drag 'n' drop moke images here, or click to select files</p>
                                      <p>PDF's not accepted (on image upload)</p>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            }
                          </div>
                        </>
                        :
                        <div className="imageGrid upload-image-center">
                          {driverSideImage && driverSideImage?.images && driverSideImage?.images.map((image, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className='imagePriviewBox'>
                                  <img src={image?.imagePreviewUrl} alt="vehicles" className='img-control' />
                                  <div className='closeSpanDiv image-cross'  >
                                    <span
                                      onClick={
                                        () => {
                                          // if (isRemoveImageActive) {
                                          driverSideImage?.images?.splice(index, 1)
                                          formData?.mokeImages?.splice(index, 1)
                                          setDriverSideImage(driverSideImage?.images)
                                          setFormData({
                                            ...formData,
                                            mokeImages: formData?.mokeImages
                                          })
                                          // }
                                        }
                                      }
                                    >&times;</span>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      }
                    </div>
                    <div className='upload-img-wrapper'>
                      <h6 className='pTagMarginLeft text-center upload-img-text text-font'>Rear image</h6>
                      {(!rearImage || rearImage?.length <= 0) ?
                        <>
                          <div className='dropzone'
                          >
                            {
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  onDrop(acceptedFiles, "rearImage")
                                }}
                                maxSize={3145728 * 10}
                                minSize={1000}
                                multiple={false}
                                onDropRejected={(file) => onDropRejected(file)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section className='text-center upload-image' style={{
                                    border: '1px dashed #000',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '15px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '6px',
                                  }}
                                  >
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <img src={Download} alt="Loading..." />
                                      <p >Drag 'n' drop moke images here, or click to select files</p>
                                      <p>PDF's not accepted (on image upload)</p>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            }
                          </div>
                        </>
                        :
                        <div className="imageGrid upload-image-center">
                          {rearImage && rearImage?.images && rearImage?.images.map((image, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className='imagePriviewBox'>
                                  <img src={image?.imagePreviewUrl} alt="vehicles" className='img-control' />
                                  <div className='closeSpanDiv image-cross' >
                                    <span
                                      onClick={
                                        () => {
                                          // if (isRemoveImageActive) {
                                          rearImage?.images?.splice(index, 1)
                                          formData?.mokeImages?.splice(index, 1)
                                          setRearImage(rearImage?.images)
                                          setFormData({
                                            ...formData,
                                            mokeImages: formData?.mokeImages
                                          })
                                          // }
                                        }
                                      }
                                    >&times;</span>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      }
                    </div>
                    <div className='upload-img-wrapper'>
                      <h6 className='pTagMarginLeft text-center upload-img-text text-font'>Passenger side image</h6>
                      {(!passengerSideImage || passengerSideImage?.length <= 0) ?
                        <>
                          <div className='dropzone'
                          >
                            {
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  onDrop(acceptedFiles, "passengerSideImage")
                                }}
                                maxSize={3145728 * 10}
                                minSize={1000}
                                multiple={false}
                                onDropRejected={(file) => onDropRejected(file)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section className='text-center upload-image' style={{
                                    border: '1px dashed #000',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '15px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '6px',
                                  }}
                                  >
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <img src={Download} alt="Loading..." />
                                      <p >Drag 'n' drop moke images here, or click to select files</p>
                                      <p>PDF's not accepted (on image upload)</p>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            }
                          </div>
                        </>
                        :
                        <div className="imageGrid upload-image-center">
                          {passengerSideImage && passengerSideImage?.images && passengerSideImage?.images.map((image, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className='imagePriviewBox'>
                                  <img src={image?.imagePreviewUrl} alt="vehicles" className='img-control' />
                                  <div className='closeSpanDiv image-cross' >
                                    <span
                                      onClick={
                                        () => {
                                          // if (isRemoveImageActive) {
                                          passengerSideImage?.images?.splice(index, 1)
                                          formData?.mokeImages?.splice(index, 1)
                                          setPassengerSideImage(passengerSideImage?.images)
                                          setFormData({
                                            ...formData,
                                            mokeImages: formData?.mokeImages
                                          })
                                          // }
                                        }
                                      }
                                    >&times;</span>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      }
                    </div>
                  </div>

                  <div className='error_alert_msg'>{isError ? errorMsg : ""}</div>
                  <div className='step4-next--btn'>
                    <button className='next__btn'
                      // disabled={!checked || !isNext}
                      onClick={onUpload}>{
                        // "Next"
                        isNext ? "Upload & Checkout" : "Please wait"
                      }</button>
                  </div>
                </div>
              </div>
            </div>
            {isLoading && <Loading />}
          </>
      }
    </>
  )
}

// resize image size

function compressedImage(img) {
  
  var canvas = document.createElement('canvas');

  var width = img.width;
  var height = img.height;
  var max_width = 2048;
  var max_height = 1536;

  if (width > height) {
    if (width > max_width) {
      height = Math.round(height *= max_width / width);
      width = max_width;
    }
  } else {
    if (height > max_height) {
      width = Math.round(width *= max_height / height);
      height = max_height;
    }
  }

  canvas.width = width;
  canvas.height = height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);

  return canvas.toDataURL("image/jpeg",0.7)

}

export default ThankYouPage

