import axios from 'axios';
import React, { useState, useEffect } from 'react'
import InputMask from 'react-input-mask';
import Header from '../component/Header';

const Contact = () => {
  const [allfiled, setallfiled] = useState();
  const [mailconfirmation, setmainconfirmation] = useState('');
  const [userform, setuserform] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })

  const [validation, setValidation] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    allfileds: ''
  });

  const Formsubmit = async () => {
    checkValidation();
    let errors = validation;

    if (validation.name == '' && validation.email == '') {
      errors.allfileds = '';
      const mailconfirm = await axios.post('https://wemoke-api.dock.bot/api/chatbot/contact', userform)
      if (mailconfirm) {
        setmainconfirmation('Thank you! Your response has been recorded')
        setuserform({
          name: '',
          email: '',
          phone: '',
          message: '',
        })


      }

    } else {
      checkValidation();
      errors.allfileds = 'please fill required fileds';
      setallfiled('please fill required fileds');
    }
  }

  useEffect(() => {
    document.title ="Contact Us | WeMoke | Moke Rental"
    document.getElementById('meta-description').content="Contact us at info@mokerentalcars.com (888) 985-3027 for Moke booking, partnering with us."
    window.scrollTo(0, 0);
    checkValidation();
  }, [userform]);


  const checkValidation = () => {
    let errors = validation;

    //first Name validation
    if (!userform.name.trim()) {
      errors.name = "Name is required";
    } else {
      errors.name = "";
    }
    //last Name validation
    if (!userform.phone.trim()) {
      errors.phone = "phone number required";
    } else if (userform.phone.length < 10) {
      errors.phone = "phone number al lest 10 digit";
    } else {
      errors.phone = "";
    }

    // email validation
    const emailCond =
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    if (!userform.email.trim()) {
      errors.email = "Email is required";
    } else if (!userform.email.match(emailCond)) {
      errors.email = "Please enter a valid email address";
    } else {
      errors.email = "";
    }
    setValidation(errors);
  };




  const inputHandler = (e) => {
    setuserform({
      ...userform,
      [e.target.name]: e.target.value
    })
  }


  return (
    <>

      <section className='contact-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 contact--wrapper'>
              <h2>Contact Us</h2>
              <p>Please inquire about renting a Moke for special events and corporate branding, or if you’re interested in becoming a WeMoke partner.</p>
              <p>
                Do you have any questions, suggestions or feedback? We’d love to hear from you!


              </p>
            </div>

            <div className='col-lg-12'>
              <div className='contact-form'>


                <ul className='form-listing'>
                  <li>
                    <input type="text" placeholder='Name' value={userform.name} className='inp-style' name='name' onChange={(e) => { inputHandler(e) }} />
                    {validation.name && <p>{validation.name}</p>}

                  </li>
                  <li>
                    <input type="email" name='email' placeholder='Email' value={userform.email} className='inp-style' onChange={(e) => { inputHandler(e) }} />
                    {validation.email && <p>{validation.email}</p>}
                  </li>
                  <li className='flex-inp'>
                    <span className='country-code'>+1</span> |
                    <InputMask name='phone' placeholder='999 999 9999' value={userform.phone} className='inp-style' mask=' 999 999 9999' onChange={(e) => { inputHandler(e) }}></InputMask>
                  </li>
                  <li>
                    <textarea placeholder='Message' className='textarea-style' value={userform.message} name='message' onChange={(e) => { inputHandler(e) }}></textarea>
                  </li>
                  <li>
                    <button className='btn-filled' onClick={Formsubmit}>SUBMIT</button>
                  </li>
                </ul>
                {mailconfirmation && <div className='success-msg'>{mailconfirmation}</div>}
              </div>
            </div>


            <div className='col-lg-12'>
              <div className="map-wrap">
                <div className="map-sec">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.388486325277!2d-80.1407935857632!3d25.757728783638623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b4fe955b6989%3A0xfe5b129873b849db!2s19114%20Fisher%20Island%20Dr%2C%20Miami%20Beach%2C%20FL%2033109%2C%20USA!5e0!3m2!1sen!2sin!4v1653547194535!5m2!1sen!2sin" ></iframe>
                </div>
                <div className="map-txt">
                  <h4><span>tel:</span><a href="tel:+18889853027">(888) 985-3027</a></h4>
                  <h4><span>Email:</span><a href="mailto:admin@wemoke.com">admin@wemoke.com</a></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact