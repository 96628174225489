import React, { useState } from 'react'
import axios from 'axios'
import header from '../../images/version2/partner-header.png'
import benifit from '../../images/version2/partner-benifit.png'
import amenities from '../../images/version2/amenities.png'

function Partner() {
  const [formData, setFormData] = useState({
    businessName: "",
    address: "",
    state: "",
    city: "",
    contactName: "",
    email: "",
    phoneNumber: "",
    message: ""
  })
  const [isError, setIsError] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const [successMsg, setSuccessMsg] = useState("")
  const onChange = e => {
    const { name, value } = e?.target;
    setIsError(false)
    setFormData({ ...formData, [name]: value });
  }
  async function submit() {
    try {
      let err;
      const {
        businessName,
        address,
        state,
        city,
        contactName,
        email,
        message
      } = formData;
      if (
        businessName?.length <= 0 ||
        address?.length <= 0 ||
        state?.length <= 0 ||
        city?.length <= 0 ||
        contactName?.length <= 0 ||
        email?.length <= 0 ||
        message?.length <= 0
      ) {
        err = true;
        setIsError(true)
        setErrMsg("Please fill all the required fields!")
      }
      if (!err) {
        const { data } = await axios.post(window.location.hostname === "localhost" ? "http://localhost:5000/partnerContact" : "https://stg-api.wemoke.com/partnerContact", formData)
        if (data?.success) {
          setSuccessMsg("Thank you! Your response has been recorded.")
          setFormData({
            businessName: "",
            address: "",
            state: "",
            city: "",
            contactName: "",
            email: "",
            phoneNumber: "",
            message: ""
          })
        }
        else{
          setErrMsg(data?.message)
          setIsError(true)
        }
      }
    } catch (error) {
      setIsError(true)
      setErrMsg(error?.message)
    }
  }
  return (
    <>
      <div className='partner-page-wrapper'>
        <div className='header-wrapper'>
          <div className='container'>
            <h2>WeMoke Partners</h2>
            <p>Join Our Rental Program!</p>
            <div className='header-img'>
              <img src={header} alt="wemoke-partner" />
            </div>
          </div>
        </div>
        <div className='Benefits-wrapper'>
          <div className='container'>
            <div className='row justify-content-center '>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                <h2>Benefits</h2>
                <p>WeMoke will provide your hotel
                  with a <strong>Moke for free</strong> (MSRP
                  $28,000), and you can apply your
                  <strong>logo and branding</strong> on the Moke.
                  This generates <strong>free marketing</strong> and
                  <strong>advertising</strong> when the Moke is
                  rented and driven all over town.
                  It's like having a <strong>driving billboard</strong>
                  for your property! All at no cost.
                  Mokes are one of the <strong>most
                    photographed</strong> vehicles, and are
                  posted all over social media. It's a
                  great opportunity to <strong>elevate your
                    social media presence.</strong></p>
              </div>
              <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12'>
                <div className='benifit-img'>
                  <img src={benifit} alt="wemoke-benifit" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='Added-amenities'>
          <div className='container'>
            <div className='row justify-content-center '>
              <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 '>
                <div className='amenities-img'>
                  <img src={amenities} alt="wemoke-amenities" />
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 '>
                <div className='amenities-text'>
                  <h2>Added amenities</h2>
                  <p>Hotel guests can <strong>rent a Moke</strong> at your
                    property for <strong>local adventures, beach
                      days, sightseeing and weddings.</strong> They
                    are perfect for resort destinations,
                    cruise ports, urban cores and beach
                    towns.
                    Plus, you will be providing your
                    guests with an <strong>extra amenity</strong> they
                    will love. Our <strong>eco-friendly, electric
                      Mokes</strong> are street legal, fun to drive
                    and social-media savvy.
                    The <strong>exposure</strong> for your property is
                    immeasurable.
                    It's truly a <strong>win-win</strong> partnership.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='partner-points-wrapper'>
          <div className='container'>
            <div className='row '>
              <ul className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                <li>Wemoke handles the rentals through our website for a turn-key, easy operation. </li>
                <li>Each WeMoke comes with a lockbox for key storage & entry.</li>
                <li>WeMoke provides the insurance and adds you as additionally insured (no liability).</li>
                <li> WeMoke services each Moke</li>
                <li>Wemoke provides the Mokes at zero cost.</li>
              </ul>
              <ul className='col-xl-6 col-lg-6 col-md-12 col-sm-12 '>
                <li>The resort/business will be part of our revenue-sharing program and earn money on each rental.</li>
                <li>WeMoke will drive traffic to the property, targeting an affluent demographic.</li>
                <li>WeMoke will market this partnership and promote your venue as a place to stay and rent the Moke.</li>
                <li>Mokes are a perfect blank canvas to customize with your branding for increased visibility around town. </li>
              </ul>
            </div>
            <div className='contact-form'>
              <ul className='row form-listing'>
                <li className='col-12'>
                  <input
                    type="text"
                    placeholder='Business name*'
                    className='inp-style'
                    name='businessName'
                    value={formData?.businessName}
                    onChange={onChange}
                  />
                </li>
                <li className='col-xl-6 col-lg-6 col-md-6 col-lg-12'>
                  <input
                    type="text"
                    name='address'
                    placeholder='Address*'
                    className='inp-style'
                    value={formData?.address}
                    onChange={onChange}
                  />
                </li>
                <li className='col-xl-6 col-lg-6 col-md-6 col-lg-12'>
                  <input
                    type="text"
                    name='city'
                    placeholder='City*'
                    className='inp-style'
                    value={formData?.city}
                    onChange={onChange}
                  />
                </li>
                <li className='col-xl-6 col-lg-6 col-md-6 col-lg-12'>
                  <input
                    type="text"
                    name='state'
                    placeholder='State*'
                    className='inp-style'
                    value={formData?.state}
                    onChange={onChange}
                  />
                </li>
                <li className='col-xl-6 col-lg-6 col-md-6 col-lg-12'>
                  <input
                    type="text"
                    name='contactName'
                    placeholder='Contact name*'
                    className='inp-style'
                    value={formData?.contactName}
                    onChange={onChange}
                  />
                </li>
                <li className='col-xl-6 col-lg-6 col-md-6 col-lg-12'>
                  <input
                    type="email"
                    name='email'
                    placeholder='Email*'
                    className='inp-style'
                    value={formData?.email}
                    onChange={onChange}

                  />
                </li>
                <li className='flex-inp col-xl-6 col-lg-6 col-md-6 col-lg-12'>
                  <input
                    name="phoneNumber"
                    placeholder="999 999 9999"
                    className="inp-style"
                    value={formData?.phoneNumber}
                    onChange={onChange}
                  />
                </li>
                <li className='col-12'>
                  <textarea
                    placeholder='Message'
                    className='textarea-style'
                    name='message'
                    value={formData?.message}
                    onChange={onChange}
                  ></textarea>
                </li>
                <li className='col-8'>
                  <button
                    className='btn-filled'
                    type='submit'
                    onClick={submit}
                  >SUBMIT</button>
                </li>
              </ul>
              <div className={isError ? "" : "success-msg"}>{isError ? errMsg : successMsg}</div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default Partner
