import React, { useEffect } from 'react'
import ImgThen from '../../src/images/then-img.png'
import ImgNow from '../../src/images/now-img.png'
import ImgWhatOne from '../../src/images/what-img-01.png'
import ImgWhatTwo from '../../src/images/what-img-02.png'
import ImgWhatThree from '../../src/images/what-img-03.png'

// .......versionte.....
import v2History from '../images/version2/history.png'

const History2 = () => {

  useEffect(() => {
    document.title = "WeMoke | Moke car | History"
    window?.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className='version-two-history'>
        <div className='history-image'>
          <div className='container'>
            <h4 className='history-heading'>History</h4>
            <div className='hero-img-wrap'>
              <img src={v2History} alt="" />
              <div className='overlay'></div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 '>
              <div className='mock-what'>
                <div className='moke--text'>
                  <h4>What is Moke?</h4>
                  <p>
                    We’re a street legal, open-air, electric cruiser meant for local driving.
                    Our easy-breezy electric vehicle seats 4 people, and features a Blueto
                    oth radio, backup camera and headlights. Classified as a low-speed vehicle (LSV), i
                    t reaches a top speed of 25 mph, allowing for use on streets with a posted speed limit
                    of 35 mph or less. Drivers must be 21 years old and possess a valid U.S. driver’s license.   </p>
                </div>
                <ul className='what-listing'>
                  <li>
                    <img src={ImgWhatOne} alt="" />
                  </li>
                  <li>
                    <img src={ImgWhatTwo} alt="" />
                  </li>
                  <li>
                    <img src={ImgWhatThree} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <ul className='then-now-listing'>
                <li>
                  <div className='left-sec then-bottom-space'>
                    <img src={ImgThen} alt="" />
                  </div>
                  <div className='right-sec  right--para'>
                    <h4>Then</h4>
                    <p className='then-para'>
                      Originally commissioned for the British military in the late 1950s, these doorless vehicles turned out to be less than suitable for warfare. Luckily, they caught on with the public, who fell in love with the “Mini Moke.” It helped that the Moke was created by Sir Alec Issigonis, the father of the original Austin Mini, who was an expert on marketing niche cars. It wasn’t too long before the Moke was embraced by celebrities like Brigitte Bardot, Jackie O and the Beach Boys. Even 007 got in on the action in four James Bond movies: Live and Let Die, You Only Live Twice, Moonraker and The Spy Who Loved Me. Surfers and tourists also enjoyed cruising around destinations like Hawaii, St. Barts, Macau, Mauritius and Barbados in the little Moke.                    </p>
                  </div>
                </li>
                <li>
                  <div className='left-sec then-bottom-space'>
                    <img src={ImgNow} alt="" />
                  </div>
                  <div className='right-sec right--para'>
                    <h4>Now</h4>
                    <p className='then-para'>
                      Now, you can drive a piece of history by renting from our brand new fleet of street legal, electric, automatic beach cruisers. Each Moke weighs 2,300 pounds, has 14-inch tires and comes with seatbelts, Bluetooth radio, backup camera and lights. Classified as a low speed vehicle (LSV), it reaches a top speed of 25 mph, allowing for use on streets with a posted speed limit of 35 mph or less. With electric technology, the Moke experience is quiet, smooth and good for the planet. Click and cruise with keyless app-enabled entry. The key is located in a lockbox and renters will receive the code via SMS for easy access right before their rental starts.                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default History2