import React, {useEffect} from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import HeroFaq from '../../src/images/hero-faq.png'
import IcPlay from '../../src/images/ic-play.svg'
import faqicon from '../../src/images/faq-icon.png'
import FAQs from "react-faq-component";
const data = {
  title: "Basic Information ",
  rows: [
      {
          title: "How old do I have to be to rent a Moke?",
          content: `21 or older with a valid US driver’s license.`,
      },
      {
          title: "Can I drive the Moke on the road?",
          content:
              "Yes! The Moke is street legal and only goes 25mph. The Moke can be driven on all roads with a speed limit of 35 MPH or less. The Moke cannot be driven on sidewalks, bike paths, the beach the golf course or highways. ",
      },
      {
          title: "What is the range on the Moke?",
          content: `Up to 40 miles or 8 hours. More than enough to cruise around town and to the beach. However,
           be sure to always have the power cord with you so you can charge it while you are not driving the Moke. `,
      },
      {
          title: "How many people can ride in the Moke?",
          content: `Up to four people can fit in the Moke (1 driver + 3 passengers). Seat belts are required. No children under 13 years of age are allowed in the front passenger seat. `,
      },
      
  ],
};

const data2 = {
  title: "Scenarios",
  rows: [
      {
          title: "What if my lockbox does not open?",
          content: `Please try your code that was sent to you by SMS.  This code is only valid 15 minutes prior to your rental time and 15 minutes after your rental time. 
           If you cannot open the lockbox, please ask someone at your location to open it for you or call Moke customer service at <a href="tel:+18889853027">+1 888-985-3027</a>.`,
      },
      {
          title: "I have a flat tire, what do I do?",
          content:
              "There is a spare tire on the back of your Moke. You are responsible for changing a flat tire before returning yourMoke rental.",
      },
      {
          title: "What should I do if I get in an accident?",
          content: `Please call 911. Be sure to report the accident to your insurance company and call WeMoke customer service at <a href="tel:+18889853027">+1 888-985-3027</a> . `,
      },
      {
          title: "What if I notice damage to the Moke during my rental?",
          content: `Any damage must be reported prior to your rental start time. Evidence of the vehicles condition is required to be documented on all 4 sides before the Moke is turned on, and after the Moke is returned, through a simple ‘upload photo’ process. `,
      },
      {
        title: "What if I notice damage to the Moke prior to starting my rental?",
        content: `Please call customer service at <a href="tel:+18889853027">+1 888-985-3027</a> and take a picture of any damage. 
        Evidence of the vehicles condition is required to be documented on all 4 sides before the Moke is turned on, and after the Moke is returned, through a simple ‘upload photo’ process.  `,
    },
      
  ],
};

const data3 = {
  title: "Electrical Questions",
  rows: [
      {
          title: "Do I have to fill up or charge the Moke?",
          content: `Please take the charging cord with you during your rental and leave it in the trunk. We prefer that you charge the Moke when not in use and to assure you will not run low on battery. <a href="https://dash.wemoke.com/drivingInstructions.pdf" target="_blank" class="pdflink">Click here</a> for instructions. `,
      },
      {
          title: "What should I do if my car runs out of battery during my rental period?",
          content:
              "You should have placed the power cord in the trunk when you picked up your Moke.  Please find the nearest 110 outlet to charge your Moke.  If you do not have the power cord, please call a tow service to return the Moke to the pick-up location to charge.  The renter is financially liable for towing services.  <a href='https://dash.wemoke.com/drivingInstructions.pdf' target='_blank' class='pdflink'>Click here</a> for instructions.",
      },     
      
  ],
};

const data4 = {
  title: "Electrical Questions",
  rows: [
      {
          title: "Do I have to fill up or charge the Moke?",
          content: `Please take the charging cord with you during your rental and leave it in the trunk. We prefer that you charge the Moke when not in use and to assure you will not run low on battery. Click HERE for instructions. `,
      },
      {
          title: "What should I do if my car runs out of battery during my rental period?",
          content:
              "You should have placed the power cord in the trunk when you picked up your Moke.  Please find the nearest 110 outlet to charge your Moke.  If you do not have the power cord, please call a tow service to return the Moke to the pick-up location to charge.  The renter is financially liable for towing services. Click HERE for instructions.",
      },     
      
  ],
};
const styles = {
  // bgColor: 'white',
  titleTextColor: "black",
  rowTitleColor: "black",
  // rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  animate: true,
  arrowIcon: <img src={faqicon} className="arrow-icon"/>,
  tabFocus: true
};
const Faq = () => {
  useEffect(()=>{
    document.title ="WeMoke | Electric Moke Rentals | FAQ"
    document.getElementById('meta-description').content="Moke Booking from WeMoke - 21 years - US driving license - low speed - spare tire - rechargeable."
    //eslint-disable-next-line
  }, [])
  return (
    <>
      <section className='faq-wrap'>
        <div className='container'>
          {/* <div className='row'>
            <div className='col-lg-12'>
              <div className='hero-wrap'>
                <div className='d-flex flex-wrap align-items-center justify-content-between'>
                  <div className='banner-txt'>
                    <h2>Watch the video to get all the insights about what we are.</h2>
                    <p>
                      Still have questions? Check below all frequently asked questions.
                    </p>
                  </div>
                  <div className='banner-img'>
                    <img src={HeroFaq} alt="" />
                    <button className='playbtn'><img src={IcPlay} alt="" /></button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className='row'>
            <div className='col-lg-12'>
              <div className='faq-card'>
                <h1>FAQs</h1>
                <div className='row faq_sub_heading'>
                <FAQs
                data={data}
                styles={styles}
                config={config}
            />
             </div>
             <div className="row faq_sub_heading">
      <FAQs
                data={data2}
                styles={styles}
                config={config}
            />
                </div>
                <div className="row faq_sub_heading">
      <FAQs
                data={data3}
                styles={styles}
                config={config}
            />
                </div>
       
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Faq