import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import IcLogo from '../images/WEMOKE-01.png'
import IcMenu from '../images/ic-menu.svg'

const Header2 = () => {
  const [menu,setmenu] = useState(false);
  //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
  const toggleMenu = ()=>{
      if(menu){ 
      setmenu(false); document.body.classList.remove('hide-scroll')
      }else{
        setmenu(true);document.body.classList.add('hide-scroll') 
      }
  }
  return (
    <header>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='header-flex headerv2'>
              <Link to={'/'}><img src={ IcLogo } className="brandlogo" /></Link>
              <button className='ic-menu' onClick={toggleMenu}><img src={ IcMenu } /></button>
              <ul className={menu ? "openmenu header-links " :"header-links "}   >
                <li><Link to='/history'  className={splitLocation[2] === "history" ? "activetab" : ""} onClick={toggleMenu} >History</Link></li>
                <li><Link to='/contact' className={splitLocation[2] === "contact" ? "activetab" : ""} onClick={toggleMenu} >Contact</Link></li>
                <li><Link to='/faq' className={splitLocation[2] === "faq" ? "activetab" : ""}  onClick={toggleMenu}>FAQs</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header2