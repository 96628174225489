import React, { useState } from 'react'
import axios from 'axios'
import wemokeLogo from '../images/WEMOKE-01.png'

function Review() {
  const [formData, setFormData] = useState({ name: "", comment: "" })
  const [isSubmit, setSubmit] = useState(false)
  const onChange = e => { const { name, value } = e?.target; setFormData({ ...formData, [name]: value }) }
  const addReview = async () => {
    window?.scrollTo({
      top: 400,
      behavior: 'smooth'
    })
    try {
      const { data } = await axios.post("https://stg-api.wemoke.com/review", formData)
      setFormData({ name: "", comment: "" })
      setSubmit(true)
    }
    catch (error) {
      console.log(error)
    }
  }
  return (
    <div>

      <div className='header-section'>
        <img src={wemokeLogo} className="logoImg" alt="Wemoke-Logo" />
        <h2 className='header-heading'>Write your experience</h2>
      </div>

      <div className='formbg'>
        <input
          type="text"
          placeholder='First Name'
          name="name"
          value={formData?.name}
          onChange={onChange}
          className="review-name"
        />
        <textarea
          name="comment"
          placeholder="Comment"
          value={formData?.comment}
          onChange={onChange}
          className="review-name"
          rows={6}
        />

        <div className='review-submit-btn'>   <button
          onClick={addReview}
          disabled={isSubmit}
        >Submit</button></div>
        <div className="review-message"> {isSubmit && 'Thank you! Your response has been recorded'}</div>
      </div>
    </div>
  )
}

export default Review
