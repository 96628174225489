import React from 'react';
import './App.css';
import '../src/themes/global.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga';
// import Home from './pages/Home';
// import History from './pages/History';
// import Faq from './pages/Faq';
// import Header from './component/Header';
// import Footer from './component/Footer';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/Privacy-policy';
import TermsCondition from './pages/TermsCondition';
import ThankYouPage from './pages/bookingComplete/ThankYouPage';
import ConfirmationSignature from './pages/confimationSignature/ConfirmationSignature';
import Home2 from './pages/Home2';
import Header2 from './pages/Header2';
import Footer2 from './pages/Footer2';
import Faq2 from './pages/Faq2';
import History2 from './pages/History2';
import Review from './pages/Review';
import Partner from './pages/partner/Partner';

function App() {
  const { pathname } = useLocation()
  // useEffect(()=> {
  //   ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  //   //eslint-disable-next-line
  // }, [])
  return (
    <>
      {pathname !== "/checkout" ?
        <>
          <Header2 />
          <Routes>
            <Route exact path="/" element={<Home2 />} />
            <Route exact path="/History" element={<History2 />} />
            <Route exact path="/Contact" element={<Contact />} />
            <Route exact path="/Faq" element={<Faq2 />} />
            <Route exact path='privacy-policy' element={<PrivacyPolicy />} />
            <Route exact path='terms-condition' element={<TermsCondition />} />
            <Route exact path='acceptance' element={<ConfirmationSignature />} />
            <Route exact path='/reviews' element={<Review />} />
            <Route exact path='/partners' element={<Partner />} />
          </Routes>
          <Footer2 />
        </>
        :
        <>
          <Routes>
            <Route exact path='/checkout' element={<ThankYouPage />} />
          </Routes>
        </>
      }
    </>
  );
}

export default App;
